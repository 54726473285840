import React from "react";
import { Redirect } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import { LoginButton } from "../components/LoginButton";

const useStyles = makeStyles({
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
    },
    box: {
        margin: "1rem"
    }
});

export const Login = () => {
    const classes = useStyles();
    const { isAuthenticated } = useAuth0();

    if (isAuthenticated) {
        return <Redirect to="/" />;
    }

    return (
        <Container classes={{ root: classes.container }}>
            <Typography variant="h6">
                You must be logged in to access this site.
            </Typography>
            <Box>
                <LoginButton classes={{ root: classes.box }} />
            </Box>
        </Container>)
}