import React from "react";
import { ApplicationProvider } from "./AppContext";
import { makeStyles, createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from '@material-ui/styles';
import { BrowserRouter } from "react-router-dom";
import NavBar from "./components/NavBar";
import { Container } from "@material-ui/core";
import lightBlue from "@material-ui/core/colors/lightBlue"
import amber from "@material-ui/core/colors/amber";
import GroupLifeRouter from "./router";

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "calc(100vh - 60px)",
    position: "relative",
  },
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: lightBlue[200],
    },
    secondary: {
      main: amber[200],
    },
  },
});

const App = () => {
  const classes = useStyles();

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <ApplicationProvider>
          <NavBar />
          <Container className={classes.container}>
            <BrowserRouter>
              <GroupLifeRouter />
            </BrowserRouter>
          </Container>
        </ApplicationProvider>
      </ThemeProvider>
    </div>
  );
};

export default App;
