import React from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useAuth0 } from "@auth0/auth0-react";

import { Applicant } from "./Applicant";
import { Questions } from "./Questions";
import { Beneficiaries } from "./Beneficiaries";
import { ApprovedCoverage } from "./ApprovedCoverage";
import { DeclinedCoverage } from "./DeclinedCoverage";
import { GetStarted } from "./GetStarted";
import { ReviewApplication } from "./ReviewApplication";
import { Alert, SeverityString } from "../../components/Alert";
import { Dialog } from "../../components/Dialog";
import { enrollCustomer } from "../../api";
import { useApplicationContext } from "../../AppContext";
import samplePDF from "../../assets/sample-certificate-enrollment-form.pdf";


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        width: "fit-content",
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
            minWidth: "30rem",
            maxWidth: "40rem",
        },
    },
    stepper: {
        padding: theme.spacing(3, 0, 5),
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginLeft: -12,
    },
    buttonWrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
}));

const steps = ["Get Started", "Personal Information", "Questions", "Beneficiaries", "Get Results", "Review Application"];
const lastQuestionIdx = steps.indexOf("Get Results") - 1;


interface ErrorType {
    hasError: boolean;
    severity: SeverityString;
    message: string;
}

const initialError = {
    hasError: false,
    severity: "info" as SeverityString,
    message: '',
}

export const CertificateEnrollment: React.FC<{}> = () => {
    const history = useHistory();
    const classes = useStyles();
    const { getAccessTokenSilently } = useAuth0();
    const { ctx } = useApplicationContext()!;
    const [activeStep, setActiveStep] = React.useState<number>(0);
    const [status, setStatus] = React.useState<string>("");
    const [error, setError] = React.useState<ErrorType>(initialError)
    const [isApplicationSigned, setIsApplicationSigned] = React.useState<boolean>(false);
    const [isApplicationAccepted, setIsApplicationAccepted] = React.useState<boolean>(false);
    const [isProcessing, setIsProcessing] = React.useState<boolean>(false);
    const btnRef = React.createRef<HTMLButtonElement>();

    const handleSignature = () => {
        setIsApplicationSigned(!isApplicationSigned);
    }

    React.useEffect(() => {
        if (isApplicationSigned) {
            setTimeout(() => {
                btnRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center',
                });
            }, 350);
        }
    }, [isApplicationSigned, btnRef])

    const getStepContent = (stepNumber: number) => {
        switch (stepNumber) {
            case 0:
                return <GetStarted stepCount={steps.length - 2} />
            case 1:
                return <Applicant />
            case 2:
                return <Questions />
            case 3:
                return <Beneficiaries />
            case 4:
                return status === "APPROVED" ? <ApprovedCoverage /> : <DeclinedCoverage />
            case 5:
                const { applicant: { first_name: firstName, last_name: lastName } } = ctx;
                return <ReviewApplication
                    applicantName={`${firstName} ${lastName}`}
                    handleSignature={handleSignature}
                    isSigned={isApplicationSigned}
                    file={samplePDF}
                />
            default:
                throw new Error('Unknown step');
        }
    }

    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const resetError = () => {
        setError(initialError);
    }

    const onSubmit = async () => {
        setIsProcessing(true);
        const accessToken = await getAccessTokenSilently();
        await enrollCustomer(ctx, accessToken).then(resp => {
            if (resp.status !== 200) {
                resp.text().then((text) => {
                    setError({
                        hasError: true,
                        severity: "error" as SeverityString,
                        message: `Failed to enroll: ${text}`,
                    })
                })
            } else {
                resp.text().then(async (body) => {
                    const results = JSON.parse(body ?? "{}");
                    const { certificate_state: certificateState = {} } = results ?? {};
                    const { status } = certificateState;
                    setStatus(status);
                })
                handleNext();
            }
        }).catch(error => {
            setError({
                hasError: true,
                severity: "error" as SeverityString,
                message: `Failed to enroll: ${error?.message}`,
            })
        }).finally(() => {
            setIsProcessing(false);
        })
    };

    const toggleDialog = () => {
        setIsApplicationAccepted(!isApplicationAccepted);
    }

    const backToDashboard = () => {
        history.push("/");
    }

    const getCTAButton = (stepIdx: number) => {
        let onClickHandler = handleNext;
        let cta = "Continue";
        let disabled = isProcessing;
        if (steps[stepIdx + 1] === "Get Results") {
            onClickHandler = onSubmit;
            cta = "Submit"
        } else if (steps[stepIdx] === "Review Application") {
            onClickHandler = toggleDialog; // TODO: hook this up with api
            cta = "Accept & Continue";
            disabled = !isApplicationSigned || isProcessing;
        } else if (steps[stepIdx] === "Get Results" && status !== "APPROVED") {
            onClickHandler = backToDashboard;
            cta = "Back to Dashboard";
        }
        return <Button
            variant="contained"
            color="primary"
            onClick={onClickHandler}
            className={classes.button}
            disabled={disabled}
            ref={btnRef}
        >
            {cta}
        </Button>;
    }

    return (
        <>
            <main className={classes.layout} >
                <Paper className={classes.paper}>
                    {getStepContent(activeStep)}
                    <div className={classes.buttons}>
                        {(activeStep !== 0 && activeStep <= lastQuestionIdx) && (
                            <Button onClick={handleBack} className={classes.button}>
                                Back
                            </Button>
                        )}
                        <div className={classes.buttonWrapper}>
                            {getCTAButton(activeStep)}
                            {isProcessing && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                    </div>
                </Paper>
            </main>
            <Alert
                severity={error.severity}
                message={error.message}
                showAlert={error.hasError}
                handleClose={resetError}
            />
            <Dialog
                title="Application Submitted - WIP"
                message="This part of the application is still under development"
                isOpen={isApplicationAccepted}
                handleClose={toggleDialog}
                dialogActions={
                    <Button onClick={backToDashboard} color="primary" autoFocus variant="contained">
                        Back to Dashboard
                    </Button>
                }
            />
        </>
    );
}
