import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@material-ui/core";

interface LoginButtonProps {
    classes?: any;
}

export const LoginButton: React.FC<LoginButtonProps> = ({ classes }) => {
    const { loginWithRedirect } = useAuth0();

    return (
        <Button
            classes={classes}
            color="secondary"
            variant="contained"
            onClick={() => loginWithRedirect()}
        >
            Log In
        </Button>
    );
};
