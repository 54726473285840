import React from "react";
import styled from "styled-components";
import amber from "@material-ui/core/colors/amber";
import Typography from "@material-ui/core/Typography";
import { PDFViewer, PDFOverlay, BLUE_THEME, Level19ThemeProvider } from "@bestowinc/level19";

const SignatureHighlight = styled.div<{ isSigned: boolean }>`
    background-color: ${({ isSigned }) => isSigned ? "" : amber[200]};
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 5;
    opacity: ${({ isSigned }) => isSigned ? 1 : 0.5};
    padding-left: 0.5rem;
    &:hover {
        opacity: 0.4;
    }
`;

const DateDiv = styled.div`
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity: 1;
    padding-left: 0.5rem;
`;

const getPdfSizeProps = () => ({ initialScale: 1 });

const getUTCDate = () => {
    const date = new Date(Date.now());
    return `${date.getUTCMonth()} /${date.getUTCDate()}/${date.getUTCFullYear()} `
}

interface ReviewApplicationProps {
    applicantName: string;
    isSigned: boolean;
    handleSignature: () => void;
    file: any;
}

export const ReviewApplication: React.FC<ReviewApplicationProps> = ({ applicantName, isSigned, handleSignature, file }) => (
    <>
        <Typography gutterBottom variant="h5" component="h2">
            Review & Sign Your Application
        </Typography>
        <Level19ThemeProvider theme={BLUE_THEME}>
            <PDFViewer
                minScale={0}
                file={file}
                toolbarProps={{
                    top: "2.75rem",
                }}
                style={{ width: "100%" }}
                {...getPdfSizeProps()}
            >
                <PDFOverlay
                    coordinate={{ x: 185, y: 37 }}
                    width={175}
                    height={27}
                    page={2}
                >
                    <SignatureHighlight
                        isSigned={isSigned}
                        onClick={handleSignature}
                    >
                        {isSigned ? applicantName : "Click to Sign"}
                    </SignatureHighlight>
                </PDFOverlay>
                <PDFOverlay
                    coordinate={{ x: 421, y: 37 }}
                    width={119}
                    height={27}
                    page={2}
                >
                    <DateDiv>{isSigned && getUTCDate()}</DateDiv>
                </PDFOverlay>
            </PDFViewer>
        </Level19ThemeProvider>
    </>
);