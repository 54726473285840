import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles({
    text: {
        paddingBottom: 20,
    },
    media: {
        height: 400,
    },
    title: {
        margin: "2rem 0 1rem"
    }
});

export const DeclinedCoverage: React.FC<{}> = () => {
    const classes = useStyles();

    return (
        <Container>
            <CardContent>
                <Tooltip
                    title='Photo by Thiago Cerqueira on Unsplash'
                    placement="bottom-end"
                >
                    <CardMedia
                        className={classes.media}
                        image="declined-photo.jpg"
                    />
                </Tooltip>
                <Typography gutterBottom variant="h5" component="h2" className={classes.title}>
                    We’re Sorry
                    </Typography>
                <Typography
                    className={classes.text}
                    variant="body2"
                    color="textSecondary"
                    component="p"
                >
                    You are currently not eligible for coverage. If you feel this is an error, or have more questions, please reach out to Bestow’s customer service at: 1-833-300-0603.
                </Typography>
            </CardContent>
        </Container>
    );
};
