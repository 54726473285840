import React from 'react';
import { Route } from 'react-router-dom';
import { withAuthenticationRequired } from "@auth0/auth0-react";
import CircularProgress from '@material-ui/core/CircularProgress';

const ProtectedRoute: React.FC<any> = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={
            (props: any) => <Component {...rest} {...props} />
        } />
    )
}
export default withAuthenticationRequired(ProtectedRoute, {
    onRedirecting: () => (<div><CircularProgress /></div>)
});
