import React from "react";
import { Switch, Route } from "react-router-dom";
import { Login } from "./routes/Login";
import { CertificateEnrollment } from "./routes/CertificateEnrollment";
import ProtectedRoute from "./routes/ProtectedRoute";
import { PartnerAppCallout } from "./routes/PartnerAppCallout";
import { UnknownRoute } from "./routes/UnknownRoute";

const GroupLifeRouter = () => {
  return (
    <Switch>
      <ProtectedRoute exact path='/' component={PartnerAppCallout} />
      <ProtectedRoute path="/certificate-enrollment" component={CertificateEnrollment} />
      <Route path="/login" exact component={Login} />
      <Route component={UnknownRoute} />
    </Switch>
  );
};

export default GroupLifeRouter;
