import React from "react";
import { useApplicationContext } from "../../AppContext";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import InputMask from "react-input-mask";

const useStyles = makeStyles({
  text: {
    paddingBottom: 20,
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  inputField: {
    marginTop: 20,
  },
});

export const Applicant: React.FC<{}> = () => {
  const classes = useStyles();
  const { ctx, setCtx } = useApplicationContext()!;

  const handleChange = (event: any) => {
    const { target } = event;
    const { name, value } = target;
    const { applicant } = ctx;

    let newApplicant;
    if (name === "date_of_birth") {
      const dateArr = value.split("-");
      newApplicant = {
        ...applicant,
        [name]: {
          year: Number(dateArr[0]),
          month: Number(dateArr[1]),
          day: Number(dateArr[2]),
        },
      };
    } else if (
      name === "street_1" ||
      name === "street_2" ||
      name === "city" ||
      name === "state" ||
      name === "country" ||
      name === "postal_code"
    ) {
      const newAddress = { ...applicant.address, [name]: value };
      newApplicant = { ...applicant, address: newAddress };
    } else {
      newApplicant = { ...applicant, [name]: value };
    }
    const newCtx = { ...ctx, applicant: newApplicant };
    setCtx(newCtx);
  };

  const { applicant } = ctx;
  const {
    first_name,
    last_name,
    address,
    phone,
    date_of_birth,
    sex,
    ssn,
  } = applicant;
  const { year, month, day } = date_of_birth;
  const { street_1, street_2, city, state, country, postal_code } =
    address || "";
  return (
    <Container>
      <Typography gutterBottom variant="h5" component="h2">
        Personal Information
      </Typography>
      <form className={classes.formContainer} autoComplete="off">
        <TextField
          className={classes.inputField}
          label="First Name"
          name="first_name"
          defaultValue={first_name}
          id="outlined-basic"
          variant="outlined"
          onChange={handleChange}
        />
        <TextField
          className={classes.inputField}
          label="Last Name"
          name="last_name"
          defaultValue={last_name}
          id="outlined-basic"
          variant="outlined"
          onChange={handleChange}
        />
        <TextField
          className={classes.inputField}
          label="Address Line 1"
          name="street_1"
          defaultValue={street_1}
          id="outlined-basic"
          variant="outlined"
          onChange={handleChange}
        />
        <TextField
          className={classes.inputField}
          label="Address Line 2"
          name="street_2"
          defaultValue={street_2}
          id="outlined-basic"
          variant="outlined"
          onChange={handleChange}
        />
        <TextField
          className={classes.inputField}
          label="City"
          name="city"
          defaultValue={city}
          id="outlined-basic"
          variant="outlined"
          onChange={handleChange}
        />
        <TextField
          className={classes.inputField}
          label="State"
          name="state"
          defaultValue={state}
          id="outlined-basic"
          variant="outlined"
          onChange={handleChange}
        />
        <TextField
          className={classes.inputField}
          label="Country"
          name="country"
          defaultValue={country}
          id="outlined-basic"
          variant="outlined"
          onChange={handleChange}
        />
        <TextField
          className={classes.inputField}
          label="Zip Code"
          name="postal_code"
          defaultValue={postal_code}
          id="outlined-basic"
          variant="outlined"
          onChange={handleChange}
        />
        <InputMask
          mask="999-999-9999"
          value={phone}
          onChange={handleChange}
          maskChar=" "
          name="phone"
        >
          {(inputProps: any) => <TextField
            className={classes.inputField}
            label="Phone"
            name="phone"
            id="outlined-basic"
            variant="outlined"
            type="tel"
            {...inputProps}
          />}
        </InputMask>
        <TextField
          className={classes.inputField}
          label="Email"
          name="email"
          id="outlined-basic"
          variant="outlined"
          onChange={handleChange}
        />
        <InputMask
          mask="***-**-9999"
          value={ssn}
          onChange={handleChange}
          maskChar="*"
          name="ssn"
          alwaysShowMask={false}
        >
          {(inputProps: any) => <TextField
            className={classes.inputField}
            label="SSN"
            name="ssn"
            id="outlined-basic"
            variant="outlined"
            {...inputProps}
          />}
        </InputMask>
        <TextField
          onChange={handleChange}
          id="date"
          label="Date of Birth"
          name="date_of_birth"
          type="date"
          variant="outlined"
          defaultValue={`${year}-${month}-${day}`}
          className={classes.inputField}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <FormControl variant="outlined" className={classes.inputField}>
          <InputLabel id="demo-simple-select-outlined-label">
            Sex
              </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            onChange={handleChange}
            label="Sex"
            name="sex"
            defaultValue={sex}
          >
            <MenuItem value="MALE">Male</MenuItem>
            <MenuItem value="FEMALE">Female</MenuItem>
          </Select>
        </FormControl>
      </form>
    </Container>
  );
};
