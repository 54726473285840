import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth0 } from "@auth0/auth0-react";

import logo from "../assets/logo192.png";
import { LogoutButton } from "./LogoutButton";
import { LoginButton } from "./LoginButton";


const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
});


const NavBar: React.FC<{}> = () => {
  const classes = useStyles();
  const { isAuthenticated } = useAuth0();

  const redirectToHome = () => {
    window.location.href = `${process.env.REACT_APP_BASE_URL as string}/`
  }

  return (
    <div>
      <AppBar position="static">
        <Toolbar classes={{ root: classes.root }}>
          <Button onClick={redirectToHome} disableRipple disableFocusRipple>
            <img src={logo} alt="Logo" style={{ height: "2rem", width: "2rem", marginRight: "0.5rem" }} />
            <Typography variant="h6">
              Group Life Example App
            </Typography>
          </Button>
          {isAuthenticated ? <LogoutButton /> : <LoginButton />}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default NavBar;
