import React from "react";
import { Alert as MUIAlert } from '@material-ui/lab';
import { Snackbar } from "@material-ui/core";

export enum Severity {
    success, info, warning, error
}

export type SeverityString = keyof typeof Severity;

interface AlertProps {
    severity: SeverityString;
    message: string;
    showAlert: boolean;
    handleClose: () => void;
}

export const Alert: React.FC<AlertProps> = ({ severity, message, showAlert, handleClose }) => {
    return (
        <Snackbar
            open={showAlert}
            autoHideDuration={4000}
            onClose={handleClose}
            anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        >
            <MUIAlert
                severity={severity}
            >
                {message}
            </MUIAlert>
        </Snackbar>)
}

