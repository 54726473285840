import React from "react";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Image from '../assets/boy_legs3.jpg'

const useStyles = makeStyles({
    root: {
        width: '30rem',
        height: '15rem',
        backgroundImage: `url(${Image})`,
        backgroundSize: "100% 100%"
    },
    gridContainer: {
        height: '100%',
        padding: '1rem'
    },
    subheader: {
        maxWidth: '12rem'
    },
    ctaButton: {
        textTransform: 'unset'
    }
});

export const PartnerAppCallout: React.FC = () => {
    const history = useHistory();
    const classes = useStyles();

    const handleGetStarted = () => {
        history.push("/certificate-enrollment");
    }

    return (
        <div>
            <Typography gutterBottom variant="h5" component="h5">
                100% online life insurance
            </Typography>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
            >
                <Card className={classes.root}>
                    <Grid
                        container
                        direction="column"
                        justify="space-between"
                        alignItems="flex-start"
                        className={classes.gridContainer}
                    >
                        <CardContent>
                            <Typography gutterBottom variant="h6" component="h6" className={classes.subheader}>
                                No docs, no exams, no hassle.
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                                Apply for $2,000 in coverage today!
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button
                                size="large"
                                color="secondary"
                                variant="contained"
                                className={classes.ctaButton}
                                onClick={handleGetStarted}
                            >
                                Get Started
                            </Button>
                        </CardActions>
                    </Grid>
                </Card>
            </Grid>
        </div>
    )
};