import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useApplicationContext } from "../../AppContext";

const useStyles = makeStyles({
  text: {
    paddingBottom: 20,
  },
  media: {
    height: 400,
  },
  paperAnchorBottom: {
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    padding: "3%"
  },
  closeButton: {
    width: "fit-content",
    alignSelf: "flex-end",
    padding: "0px"
  },
  learnMoreButton: {
    margin: "1rem 0 3rem"
  },
  title: {
    margin: '1.5rem 0 1rem'
  }
});

interface GetStartedProps {
  stepCount: number;
}

export const GetStarted: React.FC<GetStartedProps> = ({ stepCount }) => {
  const classes = useStyles();
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const { ctx } = useApplicationContext()!;
  const { first_name: firstName } = ctx?.applicant ?? "Customer";


  const toggleDrawer = () => {
    setShowDrawer(showDrawer => !showDrawer);
  }

  return (
    <>
      <CardContent>
        <CardMedia
          className={classes.media}
          image="cover-photo.jpg"
        />
        <Typography variant="h5" component="h2" className={classes.title}>
          Hi {firstName},
        </Typography>
        <Typography
          className={classes.text}
          variant="body2"
          color="textSecondary"
          component="p"
        >
          We’ve got an exclusive offer from our partner Bestow that could get you term life insurance fast!
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          In just {stepCount} easy steps, add $2,000 coverage to your monthly membership. Yup, that’s right. It’s included.
        </Typography>
        <Button
          size="small"
          className={classes.learnMoreButton}
          onClick={toggleDrawer}
          disableElevation
          disableFocusRipple
          disableRipple
          endIcon={<ArrowForwardIosIcon fontSize="small" />}
        >
          Learn more
        </Button>
      </CardContent>
      <SwipeableDrawer
        anchor="bottom"
        open={showDrawer}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
        PaperProps={{
          className: classes.paperAnchorBottom
        }}
      >
        <IconButton aria-label="close" onClick={toggleDrawer} className={classes.closeButton}>
          <CloseIcon fontSize="small" />
        </IconButton>
        <Typography variant="h5" component="h5" color="textSecondary" gutterBottom>
          Group Term Life Insurance
        </Typography>
        <Typography gutterBottom variant="body2" color="textSecondary" component="p">
          We’re excited to offer our members the chance to secure term life insurance through our partner Bestow.
          It’s a great new benefit that we’ve made ridiculously easy for you. Simply, apply here and in minutes,
          you could enjoy a policy that’s backed by and A+ rating.
        </Typography>
      </SwipeableDrawer>
    </>
  );
};
