import React from "react";
import { useApplicationContext } from "../../AppContext";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Tooltip from '@material-ui/core/Tooltip';
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Typography from "@material-ui/core/Typography";
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles({
  text: {
    paddingBottom: 20,
  },
  infoButton: {
    padding: "0px 5px"
  }
});

export const Questions: React.FC<{}> = () => {
  const classes = useStyles();
  const { ctx, setCtx } = useApplicationContext()!;
  const { terminal_illness } = ctx;

  const yesClick = () => {
    const newCtx = { ...ctx, terminal_illness: true };
    setCtx(newCtx);
  };

  const noClick = () => {
    const newCtx = { ...ctx, terminal_illness: false };
    setCtx(newCtx);
  };

  return (
    <Container>
      <Typography gutterBottom variant="h5" component="h2">
        One quick question:
      </Typography>
      <Typography
        className={classes.text}
        variant="body2"
        color="textSecondary"
        component="p"
      >
        To determine your eligibility, you cannot have been diagnosed with a terminal condition.
      </Typography>
      <Typography variant="h6" color="textSecondary" component="p">
        Are you terminally ill?
        <Tooltip
          title="A terminally ill individual has a disease that cannot be cured and will lead to death."
          placement="bottom-start"
        >
          <IconButton aria-label="close" className={classes.infoButton}>
            <InfoIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Typography>
      <ButtonGroup disableElevation>
        <Button
          onClick={yesClick}
          color={terminal_illness ? "primary" : "default"}
          variant={terminal_illness ? "contained" : "outlined"}
        >
          Yes
        </Button>
        <Button
          onClick={noClick}
          color={terminal_illness ? "default" : "primary"}
          variant={terminal_illness ? "outlined" : "contained"}
        >
          No
        </Button>
      </ButtonGroup>
    </Container>
  );
};
