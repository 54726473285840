import React from "react";
import { Applicant, Beneficiary } from "./types";

type ApplicationContextType = {
  ctx: any;
  setCtx: any;
};

const ApplicationContext = React.createContext<
  ApplicationContextType | undefined
>(undefined);

export type ApplicationState = {
  applicant: Applicant;
  beneficiary: Beneficiary;
  terminal_illness: boolean;
  class_id: string;
  master_policy_id: string;
};

type Props = {
  children: React.ReactNode;
  props?: any;
};

// TODO: need to figure out how to configure the master_policy_id for this example app
const initialState = {
  applicant: {
    first_name: "John",
    last_name: "Doe",
    address: {
      street_1: "123 Main St.",
      street_2: "#45",
      city: "Austin",
      state: "TX",
      country: "USA",
      postal_code: "78701",
    },
    phone: "555-555-5555",
    date_of_birth: {
      year: 1980,
      month: 10,
      day: 10,
    },
    sex: "MALE",
  },
  beneficiary: { percent_allocated: 100, is_irrevocable: false },
  terminal_illness: false,
  class_id: "test-class-id",
  master_policy_id: "89b2cae8-1a56-42cd-aa87-68f92b69402c",
} as ApplicationState;

export const ApplicationProvider = ({ children }: Props) => {
  const [ctx, setCtx] = React.useState(initialState);

  return (
    <ApplicationContext.Provider value={{ ctx, setCtx }}>
      {children}
    </ApplicationContext.Provider>
  );
};

export const useApplicationContext = () => React.useContext(ApplicationContext);
