import { ApplicationState } from "../AppContext";
import { EnrollCustomerRequest } from "../types";

export const enrollCustomer = async (
  ctx: ApplicationState,
  token: string
): Promise<Response> => {
  const url: string = `${process.env.REACT_APP_GROUPLIFE_GATEWAY_URL}/v1/group-life/policies/enroll`;
  const headers: Record<string, string> = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const body: EnrollCustomerRequest = {
    ...ctx.applicant,
    beneficiaries: [ctx.beneficiary],
    terminal_illness: ctx.terminal_illness,
    class_id: ctx.class_id,
    master_policy_id: `${process.env.REACT_APP_MASTER_POLICY_ID}`,
  };
  const req: RequestInit = {
    method: "POST",
    headers,
    body: JSON.stringify(body),
  };
  return fetch(url, req);
};

export const getCertificate = async (
  certificateId: string = "",
  token: string
): Promise<Response> => {
  const url: string = `${process.env.REACT_APP_GROUPLIFE_GATEWAY_URL}/v1/group-life/certificates/${certificateId}`;
  const headers: Record<string, string> = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const req: RequestInit = {
    method: "GET",
    headers,
  };
  return fetch(url, req);
}



export const acceptDocuments = async (
  certificateId: string = "",
  token: string
): Promise<Response> => {
  const url: string = `${process.env.REACT_APP_GROUPLIFE_GATEWAY_URL}/v1/group-life/certificates/${certificateId}/accept-documents`;
  const headers: Record<string, string> = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const body: any = {
  };
  const req: RequestInit = {
    method: "POST",
    headers,
    body: JSON.stringify(body),
  };
  return fetch(url, req);
}