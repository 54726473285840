import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import { useApplicationContext } from "../../AppContext";

const useStyles = makeStyles({
  text: {
    paddingBottom: 20,
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  inputField: {
    marginTop: 20,
  },
});

export const Beneficiaries: React.FC<{}> = () => {
  const classes = useStyles();
  const { ctx, setCtx } = useApplicationContext()!;

  const handleChange = (event: any) => {
    const { target } = event;
    const { name, value } = target;
    const { beneficiary } = ctx;
    let newBeneficiary;
    if (name === "first" || name === "last") {
      const newName = { ...beneficiary.name, [name]: value };
      newBeneficiary = { ...beneficiary, name: newName };
    } else if (name === "date_of_birth") {
      const dateArr = value.split("-");
      newBeneficiary = {
        ...beneficiary,
        [name]: {
          year: Number(dateArr[0]),
          month: Number(dateArr[1]),
          day: Number(dateArr[2]),
        },
      };
    } else {
      newBeneficiary = { ...beneficiary, [name]: value };
    }
    const newCtx = { ...ctx, beneficiary: newBeneficiary };
    setCtx(newCtx);
  };

  return (
    <Container>
      <Typography gutterBottom variant="h5" component="h2">
        Add Your Beneficiary
        </Typography>
      <Typography
        className={classes.text}
        variant="body2"
        color="textSecondary"
        component="p"
      >
        A beneficiary is the person(s) who will receive the payout, can be anyone who depends on your income,
        and is typically tax-free. You can add additional beneficiaries after you’ve completed your application.
      </Typography>
      <form className={classes.formContainer} autoComplete="off">
        <TextField
          className={classes.inputField}
          label="First Name"
          name="first"
          id="outlined-basic"
          variant="outlined"
          onChange={handleChange}
        />
        <TextField
          className={classes.inputField}
          label="Last Name"
          name="last"
          id="outlined-basic"
          variant="outlined"
          onChange={handleChange}
        />
        <FormControl variant="outlined" className={classes.inputField}>
          <InputLabel id="demo-simple-select-outlined-label">
            Relationship
              </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            onChange={handleChange}
            label="Relationship"
            name="relationship"
          >
            <MenuItem value={"SPOUSE"}>Spouse</MenuItem>
            <MenuItem value={"DOMESTIC_PARTNER"}>Domestic Partner</MenuItem>
            <MenuItem value={"CHILD"}>Child</MenuItem>
            <MenuItem value={"PARENT"}>Parent</MenuItem>
            <MenuItem value={"FIANCE"}>Fiance</MenuItem>
            <MenuItem value={"SIBLING"}>Sibling</MenuItem>
            <MenuItem value={"FORMER_SPOUSE"}>Former Spouse</MenuItem>
            <MenuItem value={"GRANDPARENT"}>Grandparent</MenuItem>
            <MenuItem value={"BUSINESS_PARTNER"}>Business Partner</MenuItem>
          </Select>
        </FormControl>
      </form>
    </Container>
  );
};
