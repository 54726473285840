import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles({
  text: {
    paddingBottom: 20,
  },
  media: {
    height: 400,
  },
  infoButton: {
    padding: "0px 5px"
  }
});

export const ApprovedCoverage: React.FC<{}> = () => {
  const classes = useStyles();

  // <span>Photo by <a href="https://unsplash.com/@georgiadelotz?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Georgia de Lotz</a> on <a href="https://unsplash.com/s/photos/birthday?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a></span>

  return (
    <Container>
      <CardContent>
        <CardMedia
          className={classes.media}
          image="approved-photo.jpg"
        />
        <Typography gutterBottom variant="h5" component="h2" align="center">
          You're approved!
        </Typography>
        <Typography
          className={classes.text}
          variant="body2"
          color="textSecondary"
          component="p"
        >
          You've been approved for the coverage amount below. Please review
          and sign to accept.
        </Typography>
        <Typography variant="h6" color="textSecondary" component="p">
          Your Beginner plan qualifies you for
        </Typography>
        <Typography variant="h6" color="textSecondary" component="p">
          $2,000 of coverage
            <Tooltip
            title="Your current monthly plan qualifies you for this coverage amount, if you'd like more coverage you'll need to upgrade your plan."
            placement="bottom-start"
          >
            <IconButton aria-label="close" className={classes.infoButton}>
              <InfoIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Typography>
      </CardContent>
    </Container >
  );
};
