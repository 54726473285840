import React from 'react';
import { Dialog as MUIDialog } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface DialogProps {
    isOpen: boolean;
    handleClose: () => void;
    message: string;
    title: string;
    dialogActions?: React.ReactChild | React.ReactChildren;
}

export const Dialog: React.FC<DialogProps> = ({ isOpen, handleClose, title, message, dialogActions }) => {
    return (
        <MUIDialog
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            {dialogActions && (
                <DialogActions>
                    {dialogActions}
                </DialogActions>)
            }
        </MUIDialog>
    );
}